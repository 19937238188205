import { Route, Redirect, useLocation } from 'react-router-dom';
import useAuth from 'external/domains/auth/passwordless/hooks/useAuth';
import { PageLoader } from 'shared/domains/common-ui';
import { AppError } from 'shared/domains/errors';
import { SessionDialog } from '../SessionDialog';
function AuthenticatedRoute(props) {
    const { children, path } = props;
    const auth = useAuth();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const documentRedirect = params.get('document_redirect');
    if (auth.loading) {
        return <PageLoader delay={0}/>;
    }
    if (auth.error) {
        return <AppError />;
    }
    if (auth.data.authenticated !== true) {
        return (<Redirect to={{
                pathname: '/auth',
                state: {
                    from: path,
                },
            }}/>);
    }
    if (documentRedirect) {
        return (<Redirect to={{
                pathname: `/document/${documentRedirect}`,
            }}/>);
    }
    return (<Route {...props}>
      {children}
      <SessionDialog />
    </Route>);
}
export default AuthenticatedRoute;
