import { gql } from '@apollo/client';
const sharedDocumentQuery = gql `
  query GetSharedDocument($id: ID!, $stringId: String!) {
    document: fileSystemDocument(id: $id) {
      id
      name
      type

      files {
        id
        name
        type: mimeType
        signed(name: "preview") {
          url
        }
      }

      lastSignRequest {
        id
        status
      }
    }

    invites: externalSharingInvites(where: { fileSystemDocumentId: { eq: $stringId } }) {
      id
      fileSystemDocumentId

      inviter {
        name
      }

      company {
        id
        name
        logoPath
        logoUploaded
      }

      createdAt
    }
  }
`;
export default sharedDocumentQuery;
