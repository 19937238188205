import { useQuery } from '@apollo/client';
import { localizedFormatDate } from 'shared/domains/common-utils';
import sharedDocumentQuery from './query';
import { useCompanyFeatures } from '../useCompanyFeatures';
export function useSharedDocument(id) {
    var _a;
    const { data, error, loading } = useQuery(sharedDocumentQuery, {
        skip: !id,
        variables: {
            id,
            stringId: id,
        },
    });
    const { document, invites } = data || {};
    const [invite] = invites || [];
    const { company } = invite || {};
    const { id: documentId, name, type, files, lastSignRequest } = document || {};
    const transformedDocument = {
        id: documentId,
        name,
        type,
        files,
        lastSignRequest,
    };
    const transformedInvite = Object.assign({}, invite);
    if (invite === null || invite === void 0 ? void 0 : invite.createdAt) {
        transformedInvite.createdAt =
            (_a = localizedFormatDate(invite.createdAt, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })) !== null && _a !== void 0 ? _a : '';
    }
    const { companyFeatures, loading: loadingCompanyFeatures, error: companyFeaturesError, } = useCompanyFeatures(company === null || company === void 0 ? void 0 : company.id);
    return {
        data: {
            document: transformedDocument,
            invite: transformedInvite,
            companyFeatures,
            company,
        },
        error: error || companyFeaturesError,
        loading: loading || loadingCompanyFeatures,
    };
}
