import { lazy, Suspense, useEffect } from 'react';
import { useIsMobile } from '@pocketlaw/tetris';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthenticatedRoute from 'external/domains/auth/passwordless/components/AuthenticatedRoute';
import { isMobileVar } from 'shared/domains/apollo/utils/cacheVars';
import { PageLoader } from 'shared/domains/common-ui';
import { AppError } from 'shared/domains/errors';
import SignedOut from './screens/SignedOut';
const DashboardRoute = lazy(() => import(/* webpackChunkName: "dashboard" */ './screens/Dashboard'));
const DocumentPreview = lazy(() => import(/* webpackChunkName: "documentPreview" */ './screens/Document'));
const Authentication = lazy(() => import(/* webpackChunkName: "onboarding" */ './screens/Auth'));
function Routes(props) {
    const { language } = props;
    const isMobile = useIsMobile();
    useEffect(() => {
        isMobileVar(isMobile);
    }, [isMobile]);
    useEffect(() => {
        if (document.documentElement.lang !== language) {
            document.documentElement.lang = language;
        }
    }, [language]);
    return (<Suspense fallback={<PageLoader delay={500}/>}>
      <Switch>
        <AuthenticatedRoute exact path="/">
          <DashboardRoute />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/document/:id">
          <DocumentPreview />
        </AuthenticatedRoute>
        <Route exact path={['/auth', '/invite']}>
          <Authentication />
        </Route>
        <Route path="/signed-out">
          <SignedOut />
        </Route>
        <Route path="/signout">
          <Redirect to="/auth"/>
        </Route>
        <Route path="*">
          <AppError code={404}/>
        </Route>
      </Switch>
    </Suspense>);
}
export default Routes;
