import { useEffect } from 'react';
import * as auth from 'external/domains/auth/passwordless/client';
import { AuthenticatedVar } from 'shared/domains/apollo/generated/types';
import { authenticatedVar } from 'shared/domains/apollo/utils/cacheVars';
const ONE_MINUTE = 60000;
export function useValidateSession() {
    useEffect(() => {
        const interval = setInterval(() => {
            const expiredSession = auth.expiredSession();
            if (expiredSession) {
                authenticatedVar(AuthenticatedVar.NotAuthenticated);
            }
        }, ONE_MINUTE);
        return () => clearInterval(interval);
    }, []);
}
