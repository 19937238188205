import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DecoupledEditor, RestrictedEditingMode } from 'pleditor';
import * as Sentry from 'shared/domains/sentry';
import { getDocumentQuery } from './query';
import { PleditorError } from '../components/PleditorError';
import { PleditorLoader } from '../components/PleditorLoader';
import { useIsLayoutReady } from '../hooks/useIsLayoutReady';
import { PREVIEW_PLUGINS } from '../previewPlugins';
import { BasePleditorStyles, PleditorWrapper, PleditorContainer } from '../styled';
export function ExternalDocumentPreview(props) {
    var _a;
    const { document } = props;
    const isLayoutReady = useIsLayoutReady();
    const { data, loading, error } = useQuery(getDocumentQuery, { variables: { documentId: document.id } });
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const config = {
        extraPlugins: [...PREVIEW_PLUGINS, RestrictedEditingMode],
    };
    return (<Fragment>
      <BasePleditorStyles />
      <PleditorWrapper>
        <PleditorContainer $isDocumentPreview>
          {isLayoutReady && (<CKEditor config={config} editor={DecoupledEditor} data={((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.htmlBody) || ''} onError={err => Sentry.captureException(err, { tags: { feature: 'pleditor' } })}/>)}
        </PleditorContainer>
      </PleditorWrapper>
    </Fragment>);
}
