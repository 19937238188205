var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { companyFeaturesQuery } from './query';
export const useCompanyFeatures = (companyId) => {
    const _a = useQuery(companyFeaturesQuery, {
        skip: !companyId,
        variables: { companyId: companyId || '' },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const companyFeatures = (data === null || data === void 0 ? void 0 : data.companyFeatures) || [];
    return Object.assign({ companyFeatures }, rest);
};
