import { PleditorPlugin } from '../PleditorPlugin';
/**
 * Plugin to handle the suggestion mode in external sharing.
 */
export class ExternalSuggestions extends PleditorPlugin {
    static get pluginName() {
        return 'ExternalSuggestions';
    }
    init() {
        this.editor.on('ready', () => this.forceSuggestionMode(), { priority: 'low' });
    }
    forceSuggestionMode() {
        var _a, _b, _c, _d;
        const feature = this.editor.config.get('featureAvailability');
        const commentsOnly = this.editor.plugins.get('CommentsOnly');
        if (!feature.suggestions) {
            commentsOnly.isEnabled = true;
            return;
        }
        this.editor.execute('trackChanges');
        (_a = this.editor.commands.get('trackChanges')) === null || _a === void 0 ? void 0 : _a.forceDisabled('suggestionsMode');
        (_b = this.editor.commands.get('acceptSuggestion')) === null || _b === void 0 ? void 0 : _b.forceDisabled('suggestionsMode');
        (_c = this.editor.commands.get('acceptAllSuggestions')) === null || _c === void 0 ? void 0 : _c.forceDisabled('suggestionsMode');
        (_d = this.editor.commands.get('discardAllSuggestions')) === null || _d === void 0 ? void 0 : _d.forceDisabled('suggestionsMode');
    }
}
